










































































































































import { Component, Vue } from "vue-property-decorator";
import CurveGraph from "@/components/CurveGraph.vue";
import MultiFreqDiagram from "@/components/MultiFreqDiagram.vue";

import ValueDisplay from "@/components/ValueDisplay.vue";
import ValueInput from "@/components/ValueInput.vue";
import WallEditor from "@/components/WallEditor.vue";
import RoomPreview from "@/components/RoomPreview.vue";
import ExplanationHint from "@/components/ExplanationHint.vue";
import { Project, ProjectDB, PROJECT_DATABASE } from "@/res/project";
import { SharedFunctions, store } from "@/state/state";
import Navbar from "@/components/Navbar.vue";

@Component({
  components: {
    ValueDisplay,
    ValueInput,
    CurveGraph,
    MultiFreqDiagram,
    WallEditor,
    RoomPreview,
    ExplanationHint,
    Navbar
  }
})
export default class Home extends Vue {
  projectList: Project[] = this.getProjectArray();

  getProjectArray(): Project[] {
    return Array.from(PROJECT_DATABASE.values()).reverse();
  }

  openProject(project: Project) {
    store.setCalc(project.projectData);
    store.roomUUID = project.uuid;
    store.roomName = project.title;
    store.roomDescription = "not implemented (todo)";
    this.$router.push({ name: "Calculation" });
  }

  async duplicateProject(project: Project) {
    const duplicated = await SharedFunctions.duplicateProject(project);
    if (duplicated) {
      this.$router.push({ name: "Calculation" });
    }
  }

  async deleteProject(project: Project) {
    let confirmed = await SharedFunctions.promptConfirmation(
      "Do you really want to delete this Project?"
    );
    if (confirmed) {
      await ProjectDB.deleteProjectByUUID(project.uuid as string);
      this.projectList = this.getProjectArray();
    }
  }
}
