







import { RTDeviationRule } from "@/calc/din-requirements";
import { OctoFrequential } from "@/calc/room";
import {
  getDINCorridorPath,
  getDINTargetRTPath,
  DRAWING_STYLES
} from "@/helper/diagram";
import { Component, Prop, Vue } from "vue-property-decorator";
import { store } from "@/state/state";
/** Draws a octo frequency SVG line inside a MultiFreqDiagram */
@Component({
  components: {}
})
export default class MultiFreqCorridor extends Vue {
  @Prop({ type: Array })
  dinCorridor!: OctoFrequential<RTDeviationRule>;

  @Prop({ type: Number })
  dinTargetRT!: number;

  @Prop({ type: Function })
  projectX!: (x: number) => number;

  @Prop({ type: Function })
  projectY!: (y: number) => number;

  get corridorSVGPath(): string {
    const asrProjectXCorridor = (x: number) => {
      return this.projectX(x + 1); // shift diagram corridor
    };
    if (store.isASR) {
      return getDINCorridorPath(
        this.dinTargetRT,
        this.dinCorridor,
        asrProjectXCorridor,
        this.projectY
      );
    } else {
      return getDINCorridorPath(
        this.dinTargetRT,
        this.dinCorridor,
        this.projectX,
        this.projectY
      );
    }
  }
  get targetSVGPath(): string {
    const asrProjectXTarget = (x: number) => {
      return this.projectX(x + 1); // shift diagram path
    };
    if (store.isASR) {
      return getDINTargetRTPath(
        this.dinTargetRT,
        asrProjectXTarget,
        this.projectY
      );
    } else {
      return getDINTargetRTPath(this.dinTargetRT, this.projectX, this.projectY);
    }
  }
  get corridorCSSStyle(): Object {
    return {
      fill: DRAWING_STYLES.corridor.color,
      fillOpacity: DRAWING_STYLES.corridor.fillOpacity
    };
  }
  get targetCSSStyle(): Object {
    return {
      strokeDasharray:
        DRAWING_STYLES.target.dasharray &&
        DRAWING_STYLES.target.dasharray.join(", "),
      stroke: DRAWING_STYLES.target.color,
      strokeWidth: DRAWING_STYLES.target.strokeWidth,
      fill: DRAWING_STYLES.target.filled ? DRAWING_STYLES.target.color : "none"
    };
  }
}
