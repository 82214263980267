import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Evolution4 from "../views/Evolution4.vue";
import Calculation from "../views/Calculation.vue";
import Results from "../views/Results.vue";
import { store } from "@/state/state";
import * as AUTH from "@/state/login";

Vue.use(VueRouter);

/** Default page title that will appear in browser tab and bookmarks. Will be extended by the routes below. */
const DEFAULT_TITLE = "Welcome to reverberate®";

/**
 * It's part of the concept that many routes use the same path. This is because not
 * all routes should create a history entry that will be accessible for users via url.
 * Note that the order of the routes array is important, because the first matched path
 * will be the matched route!
 * To link to a specific route internally the app uses programmatic navigation via
 * the name of the routes, e.g. {name: Results}
 * Navigation guards inside the routes guarantee that results page is only shown, if
 * a valid calculation result is in store.
 */
const routes: Array<RouteConfig> = [
  {
    /** Page for editing the current room configuration. */
    path: "/calc",
    name: "Calculation",
    component: Calculation,
    props: route => ({ jumpToSection: route.params.jumpToSection }),
    children: []
  },
  {
    path: "/new",
    name: "New",
    beforeEnter: (to, from, next) => {
      // Todo: Confirm discarding if there is a calculation in store
      store.resetCalculation();
      next({ name: "Calculation", replace: true });
    }
  },
  {
    /** Legacy / Debugging only! */
    path: "/evolutions/4",
    name: "Evolution4",
    component: Evolution4
  },
  {
    path: "/results",
    name: "Results!",
    meta: {
      title: `Reverberation Report`,
      scrollBelowHeader: true
    },
    component: Results,
    beforeEnter: (to, from, next) => {
      if (store.simulatedButtonPressed) {
        next();
      } else {
        next({ name: "Home", replace: true });
      }
    }
  },
  {
    path: "/home",
    name: "Home",
    component: Home
  },
  {
    path: "/about",
    name: "About",
    meta: { title: "About" },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/contact",
    name: "Contact",
    meta: { title: "Contact" },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Contact.vue")
  },
  {
    path: "/terms",
    name: "Terms And Conditions",
    meta: { title: "Terms and Conditions" },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/TermsAndConditions.vue")
  },
  {
    path: "/imprint",
    name: "Imprint",
    meta: { title: "Imprint" },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Imprint.vue")
  },
  {
    path: "/dataProtection",
    name: "Data Protection",
    meta: { title: "Data Protection" },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/DataProtection.vue")
  },
  {
    path: "/export",
    name: "Export",
    meta: { title: "Export" },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "export" */ "../views/Export.vue")
  },
  {
    path: "*",
    name: "Login",
    component: Login
  }
];

const router = new VueRouter({
  routes,
  scrollBehavior(to) {
    if (to.meta?.scrollBelowHeader) {
      return { selector: "#pageTop" };
    }
    // scroll to top on navigation. see https://router.vuejs.org/guide/advanced/scroll-behavior.html
    return { x: 0, y: 0 };
  }
});

// Got to Login, if not logged in
router.beforeEach(async (to, from, next) => {
  if (to.path.startsWith("/code=")) {
    const authCode = to.path.substring(6);
    await AUTH.getToken(authCode);
    next("/");
  } else if (
    to.path === "/about" ||
    to.path === "/terms" ||
    to.path === "/imprint" ||
    to.path === "/contact" ||
    to.path === "/dataProtection"
  ) {
    next();
  } else if (!AUTH.isSessionActive() && to.path !== "/") {
    next("/");
  } else if (AUTH.isSessionActive() && to.path === "/") {
    next("/home");
  } else {
    next();
  }
});

type titleFunction = (to: Route) => string;

/** Update page title */
router.afterEach(to => {
  let title = "";
  if (to.meta?.title) {
    if (typeof to.meta.title === "function") {
      title = (<titleFunction>to.meta.title)(to);
    } else if (typeof to.meta.title === "string") {
      title = to.meta.title;
    }
  }
  if (title) {
    title += " | ";
  }
  document.title = title + DEFAULT_TITLE;
});

export default router;
