

















































































































































































































































































import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import {
  Material,
  ABSORPTION_DATABASE,
  SCATTERING_DATABASE,
  FALLBACK_ABSORPTION_MATERIAL,
  FALLBACK_SCATTERING_MATERIAL,
  MaterialDB,
  MaterialDatabase,
  ScatteringDB
} from "@/res/materials-absorption";

import MultiFreqDiagram from "@/components/MultiFreqDiagram.vue";
import { OctoValuePlot } from "@/helper/diagram";
import AbsorptionScatteringThumbnail from "./AbsorptionScatteringThumbnail.vue";
import MaterialInputAdd from "@/components/MaterialInputAdd.vue";
import { FREQUENCIES } from "@/calc/acoustic-constants";

@Component({
  components: {
    MultiFreqDiagram,
    AbsorptionScatteringThumbnail,
    MaterialInputAdd
  }
})
export default class MaterialInput extends Vue {
  private modal: boolean = false;
  private options: Material[];
  private searchQuery: string = "";
  private update: boolean = false;
  private panel = [0, 1, 2];
  private disabled = false;
  private readonly = false;

  @VModel({ type: [Number, String] })
  inputValue!: number | string;

  @Prop({ type: String })
  type!: "absorption" | "scattering";

  constructor() {
    super();
    if (this.type === "scattering") {
      this.options = Array.from(SCATTERING_DATABASE.values());
    } else {
      this.options = Array.from(ABSORPTION_DATABASE.values());
    }
  }

  get yLabel(): string {
    return this.type === "absorption"
      ? "Absorption coefficient"
      : "Scattering coefficient";
  }

  get frequencies() {
    return FREQUENCIES;
  }

  refreshOptions(newUUID: string | null) {
    this.update = true;
    this.searchQuery = "";
    let dbType: MaterialDatabase;
    if (this.type === "scattering") {
      dbType = SCATTERING_DATABASE;
    } else {
      dbType = ABSORPTION_DATABASE;
    }
    this.options = Array.from(dbType.values());
    if (newUUID) {
      this.inputValue = newUUID;
      this.$nextTick(() => {
        Object.assign(this.currentMaterial, dbType.get(newUUID));
        this.scrollToOptionDelayed();
      });
    }
  }

  getMaterialsByType(type: number): Material[] {
    return this.options.filter(obj => obj.type === type);
  }

  openAllMaterialGroups() {
    this.panel = [0, 1, 2];
  }

  openGroupWhereTypeSelected() {
    if (this.inputValue === 1) {
      this.panel = [0];
    } else if (this.inputValue === 2) {
      this.panel = [1];
    } else if (this.inputValue === 3) {
      this.panel = [2];
    }
  }

  openPackageGroup() {
    this.panel = [2];
  }

  scrollToOptionDelayed() {
    this.openGroupWhereTypeSelected();
    setTimeout(this.scrollToOption, 200);
  }
  scrollToOption() {
    let el: any = this.$refs["option_" + this.inputValue];
    if (el) {
      el[0].$el.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest"
      });
    }
  }
  searchMaterialList() {
    if (!this.searchQuery) {
      this.openGroupWhereTypeSelected();
      this.refreshOptions(null);
      return;
    }
    this.openAllMaterialGroups();
    const DATABASE =
      this.type === "absorption" ? ABSORPTION_DATABASE : SCATTERING_DATABASE;
    this.options = Array.from(DATABASE.values()).filter(
      mat =>
        mat.uuid === this.inputValue ||
        mat.title.toLowerCase().includes(this.searchQuery.toLowerCase())
    );
  }

  clearSearch() {
    this.searchQuery = "";
    this.searchMaterialList();
  }

  get currentMaterial(): Material {
    let dB;
    if (this.type === "scattering") {
      dB = SCATTERING_DATABASE;
    } else {
      dB = ABSORPTION_DATABASE;
    }
    const mat = dB.get(String(this.inputValue));
    if (mat === undefined) {
      return {
        uuid: "",
        title: "?",
        values: [0, 0, 0, 0, 0, 0, 0, 0],
        description: "No material selected",
        source: "",
        type: 1 | 2 | 3
      } as Material;
    }
    return mat;
  }
  get octoFreqPlots(): OctoValuePlot[] {
    return [
      {
        values: this.currentMaterial.values,
        label: this.currentMaterial.title,
        drawingStyle: "line",
        bullet: "circle",
        dominant: true
      }
    ];
  }
  open() {
    this.modal = true;
    this.clearSearch();
    this.scrollToOptionDelayed();
  }
  close() {
    this.modal = false;
  }

  log(t: any) {
    console.log(t);
  }

  isEditingDisabled() {
    return this.currentMaterial.type === 1;
  }
  get tooltip() {
    return !this.isEditingDisabled()
      ? {
          color: null,
          text_edit: "Edit Material",
          text_delete: "Delete Material"
        }
      : {
          color: "#bbb",
          text_edit: "Cannot Edit Stock Material",
          text_delete: "Cannot Delete Stock Material"
        };
  }
  editMaterial() {
    (this.$refs.addMaterialPopup as MaterialInputAdd).openEditMaterial(
      this.currentMaterial
    );
  }
  async deleteMaterial(uuid: string) {
    let response: any;

    if (this.type === "absorption") {
      /** select previous material when material deleted */
      let previousUUID = MaterialDB.getPreviousUUID(this.currentMaterial.uuid);
      let previousMaterial = previousUUID
        ? ABSORPTION_DATABASE.get(previousUUID)
        : null;
      this.inputValue = previousMaterial?.uuid ?? FALLBACK_ABSORPTION_MATERIAL;

      response = await MaterialDB.remove_ABSORPTION_MATERIAL(uuid);
      if (response) ABSORPTION_DATABASE.delete(response.uuid);
    } else {
      /** select previous scattering material when material deleted */
      let previousUUID = ScatteringDB.getPreviousUUID(
        this.currentMaterial.uuid
      );
      let previousMaterial = previousUUID
        ? SCATTERING_DATABASE.get(previousUUID)
        : null;
      this.inputValue = previousMaterial?.uuid ?? FALLBACK_SCATTERING_MATERIAL;

      response = await ScatteringDB.deleteScatteringByUUID(uuid);
      if (response) SCATTERING_DATABASE.delete(uuid);
    }
    this.refreshOptions(null);
  }
}
