import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: "#1f82c0",
        secondary: "#009374",
        accent: "#8c9eff",
        error: "#b71c1c"
      }
    }
  }
});
