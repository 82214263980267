





























































































































































































































































import { Component, VModel, Vue } from "vue-property-decorator";
import {
  Furniture,
  FURNITURE_DATABASE,
  FurnitureDB
} from "@/res/furniture-surfaces";
import MultiFreqDiagram from "@/components/MultiFreqDiagram.vue";
import { OctoValuePlot } from "@/helper/diagram";
import { FREQUENCIES } from "@/calc/acoustic-constants";
import FurnitureInputAdd from "@/components/FurnitureInputAdd.vue";

@Component({
  components: {
    FurnitureInputAdd,
    MultiFreqDiagram
  }
})
export default class FurnitureInput extends Vue {
  private modal: boolean = false;
  private options: Furniture[];
  private searchQuery: string = "";
  private update: boolean = false;
  private panel = [0, 1, 2];
  private disabled = false;
  private readonly = false;
  private packageMask = 1 << 30;
  private yLabel: string = "Equivalent absorption area [m²]";

  @VModel({ type: [Number, String] })
  inputValue!: number | string;

  constructor() {
    super();
    this.options = Array.from(FURNITURE_DATABASE.values());
  }

  get frequencies() {
    return FREQUENCIES;
  }

  refreshOptions(newUUID: string | null) {
    this.update = true;
    this.searchQuery = "";
    let dbType = FURNITURE_DATABASE;
    this.options = Array.from(dbType.values());
    if (newUUID) {
      this.inputValue = newUUID;
      this.$nextTick(() => {
        Object.assign(this.currentFurniture, dbType.get(newUUID));
        this.scrollToOptionDelayed();
      });
    }
  }

  getFurnitureByType(type: number): Furniture[] {
    return this.options.filter(obj => obj.type === type);
  }

  openAllFurnitureGroups() {
    this.panel = [0, 1, 2];
  }

  openGroupWhereTypeSelected() {
    if (this.inputValue === 1) {
      this.panel = [0];
    } else if (this.inputValue === 2) {
      this.panel = [1];
    } else if (this.inputValue === 3) {
      this.panel = [2];
    }
  }

  openPackageGroup() {
    this.panel = [2];
  }

  scrollToOptionDelayed() {
    this.openGroupWhereTypeSelected();
    setTimeout(this.scrollToOption, 200);
  }
  scrollToOption() {
    let el: any = this.$refs["option_" + this.inputValue];
    if (el) {
      el[0].$el.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest"
      });
    }
  }
  searchFurnitureList() {
    if (!this.searchQuery) {
      this.openGroupWhereTypeSelected();
      this.refreshOptions(null);
      return;
    }
    this.openAllFurnitureGroups();
    this.options = Array.from(FURNITURE_DATABASE.values()).filter(
      furniture =>
        furniture.uuid === this.inputValue ||
        furniture.title.toLowerCase().includes(this.searchQuery.toLowerCase())
    );
  }

  clearSearch() {
    this.searchQuery = "";
    this.searchFurnitureList();
  }

  get currentFurniture(): Furniture {
    let dB = FURNITURE_DATABASE;
    const frnt = dB.get(String(this.inputValue));
    if (frnt === undefined) {
      return {
        uuid: this.inputValue,
        title: "Please choose an object",
        values: [0, 0, 0, 0, 0, 0, 0, 0],
        description: "No furniture selected",
        source: "",
        type: 1
      } as Furniture;
    }
    return frnt;
  }

  //Returns the highest, rounded-up value of the current furniture
  get highestValue(): number {
    let roundedUpValue = 0;

    if (this.currentFurniture !== undefined) {
      const highestValue = Math.max(...this.currentFurniture.values);
      roundedUpValue = Math.ceil(highestValue * 2) / 2;

      if (roundedUpValue < 1) {
        return 1;
      }
      roundedUpValue;

      if (roundedUpValue === highestValue) {
        roundedUpValue = Math.ceil(roundedUpValue + 0.5);
      }
    } else {
      console.log("Furniture not found");
    }
    return roundedUpValue;
  }

  get octoFreqPlots(): OctoValuePlot[] {
    return [
      {
        values: this.currentFurniture.values,
        label: this.currentFurniture.title,
        drawingStyle: "line",
        bullet: "circle",
        dominant: true
      }
    ];
  }

  open() {
    this.modal = true;
    this.clearSearch();
    this.scrollToOptionDelayed();
  }
  close() {
    this.modal = false;
  }

  log(t: any) {
    console.log(t);
  }

  isEditingDisabled() {
    return this.currentFurniture.type === 1;
  }
  get tooltip() {
    return !this.isEditingDisabled()
      ? {
          color: null,
          text_edit: "Edit Furniture",
          text_delete: "Delete Furniture"
        }
      : {
          color: "#bbb",
          text_edit: "Cannot Edit Stock Furniture",
          text_delete: "Cannot Delete Stock Furniture"
        };
  }
  editFurniture() {
    (this.$refs.addFurniturePopup as FurnitureInputAdd).openEditFurniture(
      this.currentFurniture
    );
  }

  async deleteFurniture(uuid: string) {
    let response: any;
    /** select previous furniture object when furniture deleted */
    let previousUUID = FurnitureDB.getPreviousUUID(this.currentFurniture.uuid);
    let previousMaterial = previousUUID
      ? FURNITURE_DATABASE.get(previousUUID)
      : null;
    this.inputValue = previousMaterial?.uuid ?? ""; // FALLBACK_FURNITURE_OBJECT;

    response = await FurnitureDB.deleteFurnitureByUUID(uuid);
    if (response) FURNITURE_DATABASE.delete(uuid);
    this.refreshOptions(null);
  }
}
