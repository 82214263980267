import {
  OctoFrequential,
  DIMENSION,
  RoomDirectionMultiFreq,
  TriDimensional
} from "./room";
import {
  DIN_SCENARIOS,
  RULE_STRENGTH,
  RTDeviationRule,
  RTDeviationLimits
} from "./din-requirements";
import { totalSurfaceArea } from "./sound-pressure-diffuse";
import { store } from "@/state/state";

// asr 5.2 requirements - reverberation time requirements in office spaces - 5.2.1:
// - callcenter: t = 0.5 s
// - multi-person and open office: t = 0.6 s
// - single and two-person office: t = 0.8 s

let asrScenario: number = 0;

export const EDUCATIONAL_ASR_RT_DEVIATION_LIMITS: Readonly<
  OctoFrequential<RTDeviationLimits>
> = [
  { minDeviation: 0.8, maxDeviation: 1.2 }, // 62.5 Hz
  { minDeviation: 0.8, maxDeviation: 1.2 }, // 125 Hz
  { minDeviation: 0.8, maxDeviation: 1.2 }, // 250 Hz
  { minDeviation: 0.8, maxDeviation: 1.2 }, // 500 Hz
  { minDeviation: 0.8, maxDeviation: 1.2 }, // 1 kHz
  { minDeviation: 0.8, maxDeviation: 1.2 }, // 2 kHz
  { minDeviation: 0.8, maxDeviation: 1.2 }, // 4 kHz
  { minDeviation: 0.8, maxDeviation: 1.2 } // 8 kHz
];

export const OFFICE_ASR_RT_DEVIATION_LIMITS: Readonly<
  OctoFrequential<RTDeviationLimits>
> = [
  { minDeviation: 0.8, maxDeviation: 1.2 }, // 62.5 Hz
  { minDeviation: 0.8, maxDeviation: 1.2 }, // 125 Hz
  { minDeviation: 0, maxDeviation: 1 }, // 250 Hz
  { minDeviation: 0, maxDeviation: 1 }, // 500 Hz
  { minDeviation: 0, maxDeviation: 1 }, // 1 kHz
  { minDeviation: 0, maxDeviation: 1 }, // 2 kHz
  { minDeviation: 0.8, maxDeviation: 1.2 }, // 4 kHz
  { minDeviation: 0.8, maxDeviation: 1.2 } // 8 kHz
];

export const OTHER_COMMUNICATION_ASR_RT_DEVIATION_LIMITS: Readonly<
  OctoFrequential<RTDeviationLimits>
> = [
  { minDeviation: 0, maxDeviation: 0 }, // 62.5 Hz
  { minDeviation: 0, maxDeviation: 0 }, // 125 Hz
  { minDeviation: 0, maxDeviation: 1 }, // 250 Hz
  { minDeviation: 0, maxDeviation: 1 }, // 500 Hz
  { minDeviation: 0, maxDeviation: 1 }, // 1 kHz
  { minDeviation: 0, maxDeviation: 1 }, // 2 kHz
  { minDeviation: 0, maxDeviation: 0 }, // 4 kHz
  { minDeviation: 0, maxDeviation: 0 } // 8 kHz
];

// helper function: retrieves asr rt deviation rules based on scenario
export function getASRRTDeviationRules(
  scenario: DIN_SCENARIOS
): OctoFrequential<RTDeviationRule> | null {
  let deviationRules: OctoFrequential<RTDeviationRule> | null = null;
  let ruleStrengths: OctoFrequential<RULE_STRENGTH>;

  switch (scenario) {
    case DIN_SCENARIOS.ASR_CALLCENTER:
    case DIN_SCENARIOS.ASR_OPEN_OFFICE:
    case DIN_SCENARIOS.ASR_PRIVATE_OFFICE:
      asrScenario = 0;
      ruleStrengths = [
        RULE_STRENGTH.REQUIRED, // 62.5 Hz
        RULE_STRENGTH.REQUIRED, // 125 Hz
        RULE_STRENGTH.REQUIRED, // 250 Hz
        RULE_STRENGTH.REQUIRED, // 500 Hz
        RULE_STRENGTH.REQUIRED, // 1 kHz
        RULE_STRENGTH.REQUIRED, // 2 kHz
        RULE_STRENGTH.REQUIRED, // 4 kHz
        RULE_STRENGTH.REQUIRED // 8 kHz
      ];
      break;
    case DIN_SCENARIOS.ASR_EDUCATIONAL:
      asrScenario = 1;
      ruleStrengths = [
        RULE_STRENGTH.REQUIRED, // 62.5 Hz
        RULE_STRENGTH.REQUIRED, // 125 Hz
        RULE_STRENGTH.REQUIRED, // 250 Hz
        RULE_STRENGTH.REQUIRED, // 500 Hz
        RULE_STRENGTH.REQUIRED, // 1 kHz
        RULE_STRENGTH.REQUIRED, // 2 kHz
        RULE_STRENGTH.REQUIRED, // 4 kHz
        RULE_STRENGTH.REQUIRED // 8 kHz
      ];
      break;
    case DIN_SCENARIOS.ASR_OTHER_COMMUNICATION:
      asrScenario = 2;
      ruleStrengths = [
        RULE_STRENGTH.NONE, // 62.5 Hz
        RULE_STRENGTH.NONE, // 125 Hz
        RULE_STRENGTH.REQUIRED, // 250 Hz
        RULE_STRENGTH.REQUIRED, // 500 Hz
        RULE_STRENGTH.REQUIRED, // 1 kHz
        RULE_STRENGTH.REQUIRED, // 2 kHz
        RULE_STRENGTH.NONE, // 4 kHz
        RULE_STRENGTH.NONE // 8 kHz
      ];
      break;
    case DIN_SCENARIOS.NONE:
      asrScenario = 0;
      return null;
    default:
      return null;
  }

  if (asrScenario == 0) {
    deviationRules = ruleStrengths.map((strength, fIdx) => ({
      minDeviation: OFFICE_ASR_RT_DEVIATION_LIMITS[fIdx].minDeviation,
      maxDeviation: OFFICE_ASR_RT_DEVIATION_LIMITS[fIdx].maxDeviation,
      strength
    })) as OctoFrequential<RTDeviationRule>;
  } else if (asrScenario == 1) {
    deviationRules = ruleStrengths.map((strength, fIdx) => ({
      minDeviation: EDUCATIONAL_ASR_RT_DEVIATION_LIMITS[fIdx].minDeviation,
      maxDeviation: EDUCATIONAL_ASR_RT_DEVIATION_LIMITS[fIdx].maxDeviation,
      strength
    })) as OctoFrequential<RTDeviationRule>;
  } else if (asrScenario == 2) {
    deviationRules = ruleStrengths.map((strength, fIdx) => ({
      minDeviation:
        OTHER_COMMUNICATION_ASR_RT_DEVIATION_LIMITS[fIdx].minDeviation,
      maxDeviation:
        OTHER_COMMUNICATION_ASR_RT_DEVIATION_LIMITS[fIdx].maxDeviation,
      strength
    })) as OctoFrequential<RTDeviationRule>;
  } else {
    deviationRules = ruleStrengths.map(strength => ({
      minDeviation: 1,
      maxDeviation: 1,
      strength
    })) as OctoFrequential<RTDeviationRule>;
  }

  return deviationRules;
}

// helper function: helper for asr chapter 5.2 - reverberation time requirements in office spaces
export function asrTargetReverberationTime(
  roomVolume: number,
  scenario: DIN_SCENARIOS
): number | null {
  const room = store.roomMultiFreq;
  let targetRT: number | null = null;

  if (!room) {
    console.error("Room information is missing.");
    return null;
  }

  switch (scenario) {
    case DIN_SCENARIOS.ASR_CALLCENTER:
      targetRT = 0.5;
      break;
    case DIN_SCENARIOS.ASR_OPEN_OFFICE:
      targetRT = 0.6;
      break;
    case DIN_SCENARIOS.ASR_PRIVATE_OFFICE:
      targetRT = 0.8;
      break;
    case DIN_SCENARIOS.ASR_EDUCATIONAL:
      targetRT = 0.32 * Math.log10(roomVolume) - 0.17; // formel: tsoll = (0.32 * lg v/m3 - 0.17) from asr.pdf
      break;
    case DIN_SCENARIOS.ASR_OTHER_COMMUNICATION:
      // Die Anforderung an sonstige Räume mit Sprachkommunikation 5.2.3 mit mittlerer Absorptionsgrad mindestens alfa_quer = 0,3 sind ähnlich zu
      // Raumgruppe B5 der DIN 18041, jedoch ohne Raumhöhen-Abhängigkeit, hier aber für den selben Frequenzbereich von 250 bis 2000 Hz,
      // jedoch soll hier der arithmetische Mittelwert genommen werden.

      targetRT = (0.163 * roomVolume) / (0.3 * roomSurface(room));
      break;
    case DIN_SCENARIOS.NONE:
    default:
      targetRT = null;
  }

  return targetRT;
}

function roomSurface(room: TriDimensional<RoomDirectionMultiFreq>): number {
  const Lx = room[DIMENSION.x].L;
  const Ly = room[DIMENSION.y].L;
  const Lz = room[DIMENSION.z].L;

  let surface = totalSurfaceArea(Lx, Ly, Lz);
  return surface;
}
