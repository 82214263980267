import { OctoFrequential, octoZip } from "./room";
import {
  getASRRTDeviationRules,
  asrTargetReverberationTime
} from "./asr-requirements";
import { store } from "@/state/state";

export enum DIN_SCENARIOS {
  NONE = 0,
  A1 = 1,
  A2 = 2,
  A3 = 3,
  A4 = 4,
  A5 = 5,
  B1 = 6,
  B2 = 7,
  B3 = 8,
  B4 = 9,
  B5 = 10,
  ASR_CALLCENTER = 11,
  ASR_OPEN_OFFICE = 12,
  ASR_PRIVATE_OFFICE = 13,
  ASR_EDUCATIONAL = 14,
  ASR_OTHER_COMMUNICATION = 15
}
/** Array of all scenario ids from the typescipt enum `DIN_SCENARIOS`. Includes `DIN_SCENARIOS.NONE`. */
export const SCENARIO_IDS: Readonly<number[]> = Object.values(
  DIN_SCENARIOS
).filter(enumVal => !isNaN(Number(enumVal))) as number[]; // filter out typescript enum reverse mapping. See https://stackoverflow.com/questions/43100718/typescript-enum-to-object-array

export const SCENARIO_TITLES: Readonly<string[]> = [
  "None: Continue without scenario",
  "A1: Music",
  "A2: Language/Lecture",
  "A3: Teaching/Communication and language/lecture inclusive",
  "A4: Teaching/Communication inclusive",
  "A5: Sport",
  "B1: Without residential quality",
  "B2: Short term stay",
  "B3: Longer term stay",
  "B4: Need for noise reduction and spatial comfort",
  "B5: Strong need for noise reduction and spatial comfort",
  "ASR: Callcenter",
  "ASR: Open plan office",
  "ASR: One- and two-person office",
  "ASR: Educational institutions (e.g. daycare centers, schools, universities)",
  "ASR: Other rooms with voice communication"
] as const;

export const SCENARIO_SYMBOLS: Readonly<string[]> = [
  "None",
  "A1",
  "A2",
  "A3",
  "A4",
  "A5",
  "B1",
  "B2",
  "B3",
  "B4",
  "B5",
  "ASR_CALLCENTER",
  "ASR_OPEN_OFFICE",
  "ASR_PRIVATE_OFFICE",
  "ASR_EDUCATIONAL",
  "ASR_OTHER_COMMUNICATION"
] as const;

/**
 * Describes the tolerance for deviation from the
 * din target reverberation time for one frequency.
 * */
export interface RTDeviationLimits {
  /** Minimal relative deviation from the din target RT. E.g. 0.5 means 50% * target RT */
  minDeviation: number;
  /** Maximal relative deviation from the din target RT. E.g. 1.2 means 120% * target RT */
  maxDeviation: number;
}
/**
 * Describes the tolerance for deviation from the
 * din target reverberation time for one frequency.
 * Includes the rule strength specific to one type A scenario.
 * */
export interface RTDeviationRule extends RTDeviationLimits {
  /** Minimal relative deviation from the din target RT. E.g. 0.5 means 50% * target RT */
  minDeviation: number;
  /** Maximal relative deviation from the din target RT. E.g. 1.2 means 120% * target RT */
  maxDeviation: number;
  /** If rule is treated as requirement, recommendation or not applied at all for this frequency */
  strength: RULE_STRENGTH;
}
export enum RULE_STRENGTH {
  NONE, // no limits applied
  RECOMMENDED,
  REQUIRED
}

/**
 * Maximal and minimal relative deviation from the target RT that A1, A2, A3, A4, A5 scenario have in common.
 * (Relative upper and lower limits of the tolerance corridor)
 */
const RT_DEVIATION_LIMITS: Readonly<OctoFrequential<RTDeviationLimits>> = [
  { minDeviation: 0.5, maxDeviation: 1.7 },
  { minDeviation: 0.65, maxDeviation: 1.45 },
  { minDeviation: 0.8, maxDeviation: 1.2 },
  { minDeviation: 0.8, maxDeviation: 1.2 },
  { minDeviation: 0.8, maxDeviation: 1.2 },
  { minDeviation: 0.8, maxDeviation: 1.2 },
  { minDeviation: 0.65, maxDeviation: 1.2 },
  { minDeviation: 0.5, maxDeviation: 1.2 }
];

/**
 * Returns the relative octofrequential RTDeviationRules for a specific DIN 18041 category A scenario.
 * These relative deviation limits can be used in combination with `dinTargetReverberationTime()` to get
 * the absolute maximal and minimal allowed reverberation times in seconds.
 * Returns null if a category B or no scenario is set. */
export function getRTDeviationRules(
  scenario: DIN_SCENARIOS
): OctoFrequential<RTDeviationRule> | null {
  if (scenario >= DIN_SCENARIOS.ASR_CALLCENTER) {
    store.isASR = true;
    return getASRRTDeviationRules(scenario);
  }

  let ruleStrengths: OctoFrequential<RULE_STRENGTH>;
  switch (scenario) {
    case DIN_SCENARIOS.A1:
    case DIN_SCENARIOS.A2:
    case DIN_SCENARIOS.A3:
    case DIN_SCENARIOS.A4:
      ruleStrengths = [
        RULE_STRENGTH.RECOMMENDED,
        RULE_STRENGTH.REQUIRED,
        RULE_STRENGTH.REQUIRED,
        RULE_STRENGTH.REQUIRED,
        RULE_STRENGTH.REQUIRED,
        RULE_STRENGTH.REQUIRED,
        RULE_STRENGTH.REQUIRED,
        RULE_STRENGTH.RECOMMENDED
      ];
      break;
    case DIN_SCENARIOS.A5:
      ruleStrengths = [
        RULE_STRENGTH.NONE,
        RULE_STRENGTH.NONE,
        RULE_STRENGTH.REQUIRED,
        RULE_STRENGTH.REQUIRED,
        RULE_STRENGTH.REQUIRED,
        RULE_STRENGTH.REQUIRED,
        RULE_STRENGTH.NONE,
        RULE_STRENGTH.NONE
      ];
      break;
    case DIN_SCENARIOS.NONE:
    default:
      return null;
  }

  const deviationRules = ruleStrengths.map((strength, fIdx) => ({
    minDeviation: RT_DEVIATION_LIMITS[fIdx].minDeviation,
    maxDeviation: RT_DEVIATION_LIMITS[fIdx].maxDeviation,
    strength
  })) as OctoFrequential<RTDeviationRule>;
  return deviationRules;
}
/** Calculates the target reverberation time for a category A DIN 18041 usage scenario.
 *  Returns null if a category B or no scenario is set. */
export function dinTargetReverberationTime(
  roomVolume: number,
  scenario: DIN_SCENARIOS
): number | null {
  if (scenario >= DIN_SCENARIOS.ASR_CALLCENTER) {
    store.isASR = true;
    return asrTargetReverberationTime(roomVolume, scenario);
  }

  let targetRT: number | null;

  switch (scenario) {
    case DIN_SCENARIOS.A1:
      targetRT = 0.45 * Math.log10(roomVolume) + 0.07;
      break;
    case DIN_SCENARIOS.A2:
      targetRT = 0.37 * Math.log10(roomVolume) - 0.14;
      break;
    case DIN_SCENARIOS.A3:
      targetRT = 0.32 * Math.log10(roomVolume) - 0.17;
      break;
    case DIN_SCENARIOS.A4:
      targetRT = 0.26 * Math.log10(roomVolume) - 0.14;
      break;
    case DIN_SCENARIOS.A5:
      if (roomVolume < 10000) {
        targetRT = 0.75 * Math.log10(roomVolume) - 1.0;
      } else {
        targetRT = 2;
      }
      break;
    case DIN_SCENARIOS.NONE:
    default:
      targetRT = null;
  }
  return targetRT;
}

/**
 * Returns the minimal A/V ratio for a given room height and a DIN 18041 category B
 * scenario. Returns null for category A scenarios and for DIN_SCENARIO.NONE.
 */
export function dinCatBMinAVRatio(
  roomHeight: number,
  scenario: DIN_SCENARIOS
): number | null {
  let minAVRatio: number | null;

  switch (scenario) {
    case DIN_SCENARIOS.B1:
      minAVRatio = 0; // no requirements
      break;
    case DIN_SCENARIOS.B2:
      minAVRatio =
        roomHeight <= 2.5 ? 0.15 : 1 / (4.8 + 4.69 * Math.log10(roomHeight));
      break;
    case DIN_SCENARIOS.B3:
      minAVRatio =
        roomHeight <= 2.5 ? 0.2 : 1 / (3.13 + 4.69 * Math.log10(roomHeight));
      break;
    case DIN_SCENARIOS.B4:
      minAVRatio =
        roomHeight <= 2.5 ? 0.25 : 1 / (2.13 + 4.69 * Math.log10(roomHeight));
      break;
    case DIN_SCENARIOS.B5:
      minAVRatio =
        roomHeight <= 2.5 ? 0.3 : 1 / (1.47 + 4.69 * Math.log10(roomHeight));
      break;
    case DIN_SCENARIOS.NONE:
    default:
      minAVRatio = null;
  }
  return minAVRatio;
}

export function isCategoryB(dinScenario: DIN_SCENARIOS): boolean {
  const isBCategory = [
    DIN_SCENARIOS.B1,
    DIN_SCENARIOS.B2,
    DIN_SCENARIOS.B3,
    DIN_SCENARIOS.B4,
    DIN_SCENARIOS.B5
  ].includes(dinScenario);
  return isBCategory;
}

export const DIN_CAT_B_RULE_STRENGTHS: OctoFrequential<RULE_STRENGTH> = [
  RULE_STRENGTH.NONE,
  RULE_STRENGTH.NONE,
  RULE_STRENGTH.REQUIRED,
  RULE_STRENGTH.REQUIRED,
  RULE_STRENGTH.REQUIRED,
  RULE_STRENGTH.REQUIRED,
  RULE_STRENGTH.NONE,
  RULE_STRENGTH.NONE
];

/**
 * Returns the octofrequential minimal A/V ratio for a given room height and a DIN 18041 category B
 * scenario. Returns null for the frequencies that have the `RULE_STRENGTH.NONE`.
 * Returns null for every frequency if an unsupported category A scenario is given or `DIN_SCENARIO.NONE`.
 */
export function dinCatBMinAVRatios(
  roomHeight: number,
  scenario: DIN_SCENARIOS
): OctoFrequential<number | null> {
  const minAVRatios = DIN_CAT_B_RULE_STRENGTHS.map(ruleStrength =>
    ruleStrength === RULE_STRENGTH.NONE
      ? null
      : dinCatBMinAVRatio(roomHeight, scenario)
  ) as OctoFrequential<number>;
  return minAVRatios;
}
/**
 * Returns the compliance of the AV ratio for each octofrequency:
 *  - 'true' if the minimal requirement for a frequency was met;
 *  - 'false' if the minimal requirement for a frequency was not met;
 *  - 'null' if there is no requirement for a frequency.
 */
export function dinCatBAVRatiosPassed(
  actualAVRatios: OctoFrequential<number>,
  requiredAVRatios: OctoFrequential<number | null>
): OctoFrequential<boolean | null> {
  const avRatiosPassed = octoZip(
    actualAVRatios,
    requiredAVRatios,
    (AVRatio, AVRatioMin) =>
      AVRatioMin === null ? null : AVRatio >= AVRatioMin
  );
  return avRatiosPassed;
}
